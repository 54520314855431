<template>
  <div class="viewDashBoard project-route-index">
    <div class="container">
      <transition
        name="fade"
        mode="out-in"
        @beforeLeave="beforeLeave"
        @enter="enter"
        @afterEnter="afterEnter"
      >
        <div key="1" v-if="loader" class="white-row loader-route">
          <div class="lds-sub-ripple">
            <div></div>
            <div></div>
          </div>
        </div>
        <div v-else class="white-row">
          <div class="show-project-portal-listing">
            <!-- // Filter Area -->
            <div class="general-filters">
              <div class="col-sm-12">
                <div class="row">
                  <div class="col-xl-6 col-lg-6 col-md-12 col-sm-12">
                    <div class="row top-gen-heading">
                      <h1 class="global-style-heading">
                        Lead Steps
                        <a
                          @click.prevent="refreshProjectStepCatTable"
                          href=""
                          class="refreshData"
                          ><i class="fas fa-redo-alt"></i
                        ></a>
                      </h1>
                    </div>
                  </div>
                  <div class="col-xl-6 col-lg-6 col-md-12 col-sm-12">
                      <div class="row">
                        <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6">
                          <form @submit.prevent="">
                            <div class="row">
                                <div class="form-group">
                                  <label for="gender" class="control-label"
                                    >&nbsp;</label
                                  >
                                  <div class="input-group">
                                    <input
                                      v-model="formData.search"
                                      @keyup="getIProjectStepCategoryData(1)"
                                      type="text"
                                      class="form-control"
                                      placeholder="Search..."
                                    />
                                    <div class="input-group-append">
                                      <button
                                        class="btn btn-secondary"
                                        type="button"
                                      >
                                        <i class="fa fa-search"></i>
                                      </button>
                                    </div>
                                  </div>
                                </div>
                            </div>
                          </form>
                        </div>
                        <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6">
                          <div class="row">
                            <div class="form-actions">
                              <router-link
                                type="submit"
                                id="save-form"
                                class="btn btn-primary"
                                :to="{
                                  name: 'leads.steps.create'
                                }"
                                tag="button"
                              >
                                <i class="fas fa-plus-circle"></i> Add Lead Step
                              </router-link>
                            </div>
                          </div>
                        </div>
                      </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="horizontal-row">
              <hr />
            </div>
            <section class="brands">
              <div
                class=""
                v-if="
                  typeof lead_stpes.data !== 'undefined'
                "
              >
                <div
                  v-if="data_loader"
                  class="white-row loader-route subloader"
                >
                  <div class="lds-sub-ripple">
                    <div></div>
                    <div></div>
                  </div>
                </div>
                <template v-else>
                  <div class="row"
                    v-if="Object.keys(lead_stpes.data).length"
                  >
                    <div
                      v-for="(step, step_index) in lead_stpes.data"
                      :key="step_index"
                      class="col-xl-4 col-lg-4 col-md-6 col-sm-12"
                    >
                      <div class="wrap-box">
                        <div class="header-top">
                          <dropdown>
                            <template slot="icon"
                              ><i class="fas fa-ellipsis-v"></i
                            ></template>
                            <template slot="body">
                              <ul class="list-group">
                                <li class="list-group-item">
                                  <router-link
                                    :to="{
                                      path:
                                        '/leads-steps/edit/' + step.id
                                    }"
                                    tag="a"
                                  >
                                    Edit
                                  </router-link>
                                </li>
                              </ul>
                            </template>
                          </dropdown>
                        </div>
                        <div class="box">
                          <div class="project-details">
                            <p class="project-id">ID: {{ step.id }}</p>
                            <div class="project-listing">
                              <p class="eclipse-para">
                                Step Name : {{ step.name }}
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div v-else class="row">
                    <div class="col-sm-12">
                      <div class="custom-bottom-padding">
                        <no-record-found></no-record-found>
                      </div>
                    </div>
                </div>
                </template>
              </div>
            </section>
          </div>
        </div>
      </transition>
    </div>
  </div>
</template>

<script>
import norecordfound from "../../../components/no-record-found";
import Dropdown from "bp-vuejs-dropdown";
export default {
  components: {
    Dropdown,
    "no-record-found": norecordfound
  },
  computed: {
    getActiveUserData() {
      return this.$store.state.auth;
    }
  },
  data() {
    return {
      formData: {
        search: ""
      },
      loader: true,
      data_loader: true,
      lead_stpes : {},
    };
  },
  mounted() {
    document.title = this.$router.app._route.meta.pageTitle;
    this.getIProjectStepCategoryData();
    this.loader = false;
  },
  methods: {
    refreshProjectStepCatTable() {
      this.getIProjectStepCategoryData();
    },
    async getIProjectStepCategoryData(page = 1, action = null) {
      const $this = this;
      $this.data_loader = true;
      const data = $this.$store.dispatch("getHttp", {
        url:
          "lead-steps" +
          "?" +
          "&page=" +
          page +
          "?" +
          "&search=" +
          this.formData.search
      });
      await data.then(function(response) {
        if (typeof response !== "undefined") {
          $this.lead_stpes = response.data;
          $this.data_loader = false;
        }
      });
    },
    beforeLeave(element) {
      this.prevHeight = getComputedStyle(element).height;
    },
    enter(element) {
      const { height } = getComputedStyle(element);

      element.style.height = this.prevHeight;

      setTimeout(() => {
        element.style.height = height;
      });
    },
    afterEnter(element) {
      element.style.height = "auto";
    }
  }
};
</script>
<style lang="scss" scoped>
.custom-bottom-padding {
  padding-bottom: 35px;
}
body .white-row.loader-route {
  top: 0;
}
.fade-enter-active,
.fade-leave-active {
  transition-duration: 0.3s;
  transition-property: opacity;
  transition-property: height, opacity;
  transition-timing-function: ease;
  overflow: hidden;
}
.project-route-index {
  .show-project-portal-listing {
    .general-filters {
      .form-group {
        width: 95%;
        .input-group {
          input {
            border-radius: 3px !important;
            background: #f9f9f9;
            letter-spacing: 0.28px;
            font-size: 14px;
            padding: 3px 15px;
            color: #364460;
            border: none;
            font-weight: 200;
            height: auto;
          }
          button {
            background: #f9f9f9;
            color: #3b4c54;
            border: none;
            padding: 4px 8px;
          }
        }
      }
      .form-actions {
        width: 100%;
        button {
          width: 100%;
          margin-left: 0;
          padding: 5px 0;
        }
      }
    }
    h4 {
      letter-spacing: 0.28px;
      color: #323f54;
      font-size: 14px;
      font-weight: bold;
      margin-bottom: 0;
      padding-bottom: 7px;
    }
    p {
      margin: 0;
      letter-spacing: 0.28px;
      color: #2a1c0b;
    }
    section {
      border-bottom: 1px solid #ebebeb;
      padding: 30px 0;
      &.brands {
        padding-bottom: 0px;
        padding-top: 0;
        position: relative;
        .white-row.loader-route.subloader {
          position: absolute;
          top: 0;
          z-index: 9;
          width: 100%;
          height: 100%;
        }
        h4 {
          padding: 5px 0 25px;
        }
        .wrap-box {
          background: #faf9f7 0% 0% no-repeat padding-box;
          border-radius: 3px;
          padding: 20px 15px;
          min-height: auto;
          margin-bottom: 30px;
          .header-top {
            position: absolute;
            right: 26px;
            z-index: 100;
            top: 19px;
            .bp-dropdown__btn {
              border: none;
            }
            .bp-dropdown__body {
              padding: 0;
              background-color: #fff;
              box-shadow: 0 5px 15px -5px rgba(0, 0, 0, 0.5);
              z-index: 9999;
              border-radius: 4px;
              border: none;
              margin-top: 10px;
              .list-group {
                .list-group-item {
                  border: none;
                  position: relative;
                  display: block;
                  border-bottom: 1px solid rgba(0, 0, 0, 0.125);
                  padding: 10px 20px 10px 7px;
                  text-align: left;
                  a {
                    color: #212529;
                  }
                }
              }
            }
          }
          .box {
            position: relative;
            .project-details {
              p {
                &.project-id {
                  background: #ece9df 0% 0% no-repeat padding-box;
                  border-radius: 3px;
                  display: inline-block;
                  letter-spacing: 0.24px;
                  color: #323f54;
                  font-size: 12px;
                  padding: 4px 10px;
                }
                &.project-step {
                  letter-spacing: 0.24px;
                  color: #323f54;
                  font-size: 12px;
                  padding: 3px 0 15px;
                  font-weight: 400;
                }
              }
              .project-listing {
                padding: 15px 0 0;
                p {
                  margin: 0;
                  letter-spacing: 0.28px;
                  color: #323f54;
                  font-size: 14px;
                  font-weight: bold;
                  text-transform: inherit;
                  line-height: 25px;
                }
              }
            }
            .footer-box {
              display: block;
              position: relative;
              width: 100%;
              padding: 20px 0 10px;
              border-top: 1px solid rgba(0, 0, 0, 0.1);
              .usr-role {
                p {
                  letter-spacing: 0.24px;
                  color: #918a82;
                  font-weight: 300;
                  font-size: 12px;
                }
              }
            }
          }
        }
      }
    }
  }
}

// Extra large devices (lg and less)
@media screen and (max-width: 1199px) {
}
// Extra large devices (md and less)
@media screen and (max-width: 991px) {
  body .top-gen-heading {
    padding-top: 0;
    padding-bottom: 15px;
  }
}

// Extra large devices (sm and up)
@media screen and (max-width: 767px) {
  body .global-style-heading {
    font-size: 20px;
  }
}

// Extra large devices (xs and up)
@media screen and (max-width: 575px) {
  body .global-style-heading {
    font-size: 20px;
  }
}

// Extra large devices (xl, 1200px and up)
@media (min-width: 1200) {
}
</style>
